<template>
    <v-card>
        <v-footer
            app
            padless
            absolute
        >
            <v-card
                flat
                tile
                width="100%"
                class="text-center"
                color="#000000"
                dark
            >
                <v-card-text>
                    <a v-for="icon in icons"
                        :key="icon" :href="icon.url" target="_blank">
                        <v-btn
                            class="mx-4"
                            icon
                        >
                            <v-icon size="24px">
                                {{ icon.icon }}
                            </v-icon>
                        </v-btn>
                    </a>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="white--text">
                    <span><v-icon>mdi-copyright</v-icon> Copyright {{ new Date().getFullYear() }} | Ine4c Electronics</span>
                </v-card-text>
            </v-card>
        </v-footer>
    </v-card>
</template>
<script>
    export default{
        name: 'FooterComponent',
        data(){
            return{
                icons: [
                    {icon: 'mdi-facebook', url: 'https://www.facebook.com/ine4c'},
                    {icon: 'mdi-instagram', url: 'https://www.instagram.com/ine4celectronics/'},
                    {icon: 'mdi-whatsapp', url: 'https://api.whatsapp.com/send?phone=593996023533'},
                    {icon: 'mdi-email', url: 'mailto:ine4celectronica@gmail.com?subject=Contacto%20Ine4c%20Electronics'}
                ],
            }
        }
    }
</script>