<template>
  <v-app class="myFont">
    <NavBar/>
    <v-main class="myFont">
      <router-view/>
    </v-main>
    <a href="https://api.whatsapp.com/send?phone=593996023533" target="_blank" class="btn-flotante"><v-icon color="white">mdi-whatsapp</v-icon></a>
    <FooterComponent/>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'App',
  components: {
    NavBar,
    FooterComponent
  }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.myFont {
	font-family: 'Lato', sans-serif;
	background-color: #000000;
}
.btn-flotante {
	font-size: 10px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 50%; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: #08df57; /* Color de fondo */
	padding: 18px 18px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante:hover {
	background-color: #00b45d; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
</style>