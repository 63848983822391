<template>
    <div>
        <v-app-bar
            fixed
            color="#000000"
            dark
            app
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>Ine4c Electronics</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text to="/" class="hidden-sm-and-down">
                INICIO
            </v-btn>
            <v-divider
                vertical
            ></v-divider>
            <v-btn text to="/about" class="hidden-sm-and-down">
                ACERCA DE
            </v-btn>
            <v-divider
                vertical
            ></v-divider>
            <v-btn text to="/contact" class="hidden-sm-and-down">
                CONTACTO
            </v-btn>
            <v-divider
                vertical
            ></v-divider>
            <!--
            <v-btn text to="/order" class="hidden-sm-and-down">
                PEDIDO
            </v-btn>
            <v-divider
                vertical
            ></v-divider>
            -->
            <v-btn text href="https://freelance.ine4celectronics.com/" target="_blank" class="hidden-sm-and-down">
                FREELANCE
            </v-btn>
            <v-divider
                vertical
            ></v-divider>
            <!--
            <v-btn text href="http://moodle.ine4celectronics.com/" target="_blank" class="hidden-sm-and-down">
                <v-icon>mdi-book-open-variant</v-icon> CURSOS
            </v-btn>
            -->
            <v-divider
                vertical
            ></v-divider>
            <v-btn text href="https://tienda.ine4celectronics.com" target="_blank" class="hidden-sm-and-down">
                <v-icon>mdi-store</v-icon> TIENDA
            </v-btn>
            <!-- este es un coimentario no afecta a la compilación 
            <v-btn text to="/hello" class="hidden-sm-and-down">
                <v-icon>mdi-store</v-icon> HELLO
            </v-btn> 
            -->
        
        </v-app-bar>
        <v-navigation-drawer
            app
            temporary
            dark
            v-model="drawer"
            color="#000000"
        >
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title style="font-size: 1.2rem">
                    Ine4c Electronics
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense v-for="item in items" :key="item.title">
                <v-list-item
                    color="white"
                    :to="item.url"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item href="https://cursos.ine4celectronics.com" target="_blank">
                    <v-list-item-icon>
                        <v-icon>mdi-store</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Cursos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item href="https://tienda.ine4celectronics.com" target="_blank">
                    <v-list-item-icon>
                        <v-icon>mdi-book-open-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Tienda</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <p class="font-weight-light white--text text-center mt-4"><small><v-icon>mdi-copyright</v-icon> Copyright {{ new Date().getFullYear() }} Ine4cElectronics
            </small></p>
        </v-navigation-drawer>
    </div>
</template>
<script>
    export default{
        name: 'NavBar',
        data(){
            return{
                drawer: false,
                items: [
                    {title: 'INICIO', icon:'mdi-home', url: '/'},
                    {title: 'ACERCA DE', icon:'mdi-information-variant', url: '/about'},
                    {title: 'CONTACTO', icon:'mdi-phone', url: '/contact'},
                    {title: 'PEDIDO', icon:'mdi-note', url: '/order'},
                    {title: 'HELLO', icon:'mdi-note', url: '/hello'},
                ]
            }
        }
    }
</script>
<style scoped>
  .link{
    text-decoration: none;
    color: white;
  }
</style>