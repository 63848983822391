<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="text-center">
        <div class="text-h3 font-weight-medium mb-5 white--text">
          Ingeniería en Electrónica & Control e Internet de las Cosas 
        </div>
        <div class="text-h6 font-italic mb-5 orange--text">
          Bienvenido al sitio que buscabas  -  Más que avances tecnológicos promovemos tu progreso
        </div>
      </v-col>
      <v-col>
        <v-carousel   
          
          cycle
          height="500"
          hide-delimiter-background
          show-arrows-on-hover
          class="mb-5"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
          >
            <v-row
              class="fill-height ml-5"
              align="end"
              justify="start"
            >
              <div class="text-h3">
                {{ item.text }}
              </div>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-center" style="background-color: #f5e603;">
        <!-- 
        <div class="text-h4 font-weight-medium font-italic white--text">
          <a href="https://freelance.ine4celectronics.com/" target="_blank" class="white--text">VISITA FREELANCE</a>
        </div>
        -->
        <div class="text-h4 font-weight-medium font-italic white--text">
          <a href="https://cursos.ine4celectronics.com/" target="_blank" class="white--text">Deseas Capacitarte en IoT ?</a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <div class="text-h4 text-center font-weight-medium white--text">Publica tu proyecto de ingeniería</div>
        <iframe class="mt-4" width="100%" height="450" src="https://www.youtube.com/embed/p4Hab4NoE6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </v-col>
      <v-col cols="12" md="6">
        <div class="text-h4 text-center font-weight-medium white--text">Busca un proyecto de tu interés</div>
        <iframe class="mt-4" width="100%" height="450" src="https://www.youtube.com/embed/b1suKCJV4R0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </v-col>
    </v-row>
    <v-row>
      <!--
      <v-col cols="12" md="12">
        <v-img
          max-width="500"
          class="mx-auto mb-4"
          src="../assets/new_banner.jpg"
        >
        </v-img>
      </v-col>
      -->
      <v-col cols="12" class="text-center" style="background-color: #f5e603;">
        <div class="text-h4 font-weight-medium font-italic white--text">
          <a href="https://tienda.ine4celectronics.com/" target="_blank" class="white--text">Vamos Visita nuestra Tienda</a>
          <!--VISITA NUESTRA TIENDA-->
        </div>
      </v-col>
    </v-row>


    <!--
    <v-row>
      <v-col cols="12" md="12">
        <v-img
          max-height="500"
          max-width="800"
          class="mx-auto"
          src="https://ine4celectronics.com/web/res/imagen-dispensador-anuncio.png"
        >
          <v-row
            class="fill-height ml-3"
            align="end"
            justify="start"
          >
            <v-btn
              outlined
              small
              target="_blank"
              href="https://tienda.ine4celectronics.com/producto/dispensador-de-comida-para-mascotas/"
            >VER MÁS</v-btn>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
    -->


    <v-row>
      <v-col cols="12" md="4" v-for="item in items_cards" :key="item">
        <v-card
          class="mx-auto"
        >
          <v-img :src="item.img"></v-img>
          <v-card-title
            class="text-h5"
          >{{ item.title }}</v-card-title>
          <v-card-subtitle>{{ item.text }}</v-card-subtitle>
          <v-card-actions>
            <v-btn
              :href="item.url"
              target="_blank"
              color="orange"
              outlined
            >
              <v-icon>mdi-play-circle-outline</v-icon> VER PRODUCTOS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeView',
    data () {
      return {
        items: [
          {
            src: 'https://ine4celectronics.com/web/res/Fondo_web_01a.png',
            text: 'Proyectos Electrónicos'
          },
          {
            src: 'https://ine4celectronics.com/web/res/Fondo_web_02a.png',
            text: 'Kits Robóticos'
          },
          {
            src: 'https://ine4celectronics.com/web/res/Fondo_web_03a.png',
            text: 'Todo trabajo en MDF'
          },
          {
            src: 'https://ine4celectronics.com/web/res/Fondo_web_04a.png',
            text: 'Servicio de corte y'
          }
        ],
        items_cards: [
          {
            title: 'Proyectos Electrónicos',
            text: 'Prototipos, Lámparas, Sistemas Electrónicos, Internet de las Cosas (IoT).',
            url: 'https://tienda.ine4celectronics.com/categoria-producto/electronica/',
            img: 'http://ine4celectronics.com/wp-content/uploads/2022/05/fondo_sist_elect2.jpg'
          },
          {
            title: 'Trabajos en Acrílico',
            text: 'Desde llaveros, colgantes, porta nombres, hasta decoraciones personalizadas con lámparas led.',
            url: 'https://tienda.ine4celectronics.com/categoria-producto/acrilico/',
            img: 'http://ine4celectronics.com/wp-content/uploads/2022/04/fondo_acrilico.jpg'
          },{
            title: 'Trabajos en MDF',
            text: 'Desde llaveros, cajas, recuerdos adornos personalizados, hasta artículos en 3D.',
            url: 'https://tienda.ine4celectronics.com/categoria-producto/mdf/',
            img: 'http://ine4celectronics.com/wp-content/uploads/2022/04/fondo_mdf-1.jpg'
          }
        ]
      }
    },
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.myFont {
	font-family: 'Lato', sans-serif;
}
</style>